import { Button } from 'antd';
import React, { useState } from 'react';

import { useCustomer } from '../../hooks/use-customer';
import Card, { CardBody } from '../ui/Card';
import EditAccountModal from './EditAccountModal';

const AccountDetail = ({ editable = true }: { editable?: boolean }) => {
  const { customer } = useCustomer();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  return (
    <Card>
      <CardBody className="bg-neutral-1">
        <div className="mb-4 flex items-center justify-between gap-2">
          <h4 className="mb-0 overflow-hidden text-xl font-semibold">
            {customer?.first_name} {customer?.last_name}
          </h4>
          {editable && (
            <div>
              <Button onClick={() => setIsEditFormOpen(true)}>Edit</Button>
              <EditAccountModal
                open={isEditFormOpen}
                setOpen={(open: boolean) => setIsEditFormOpen(open)}
              />
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-5 md:grid-cols-[repeat(auto-fit,minmax(100px,max-content))]">
          <DetailItem title="Payment Type">
            {(customer?.metadata?.payment_type as string) || '-'}
          </DetailItem>
          <DetailItem title="Email">{customer?.email || '-'}</DetailItem>
          <DetailItem title="Phone">{customer?.phone || '-'}</DetailItem>
        </div>
      </CardBody>
    </Card>
  );
};

const DetailItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="basis-1/2 text-sm">
      <div className="mb-1 font-medium text-light-secondary">{title}</div>
      <div className="break-words text-light-title">{children}</div>
    </div>
  );
};

export default AccountDetail;
