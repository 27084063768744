import React from 'react';

import { useCustomer } from '../../hooks/use-customer';
import { CustomerPaymentType } from '../../types/entities';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import AccountLayout, {
  BASE_ACCOUNT_PATH,
  PROFILE_PATH,
} from '../account/AccountLayout';
import MainLayout from '../layout/MainLayout';
import AccountDetail from './AccountDetail';
import CashPaymentInstruction from './CashPaymentInstruction';

const AccountPage = () => {
  const { customer } = useCustomer();

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'บัญชีของฉัน',
            link: BASE_ACCOUNT_PATH,
          },
          {
            title: 'ข้อมูลส่วนตัว',
            link: PROFILE_PATH,
          },
        ]}
        title="ข้อมูลส่วนตัว"
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="flex flex-col gap-3">
            <AccountDetail />
            {customer?.metadata.payment_type === CustomerPaymentType.CASH && (
              <CashPaymentInstruction />
            )}
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default AccountPage;
