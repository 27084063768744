import React from 'react';
import { twMerge } from 'tailwind-merge';

const Card = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-full overflow-hidden rounded-md border border-light-divider bg-neutral-1 shadow-small">
      {children}
    </div>
  );
};

const CardHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="border-b border-light-divider bg-neutral-1 py-3 px-6 text-base font-semibold text-light-title">
      {children}
    </div>
  );
};

const CardBody = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={twMerge('bg-light-lighter p-6', className)}>{children}</div>
  );
};

export default Card;

export { CardHeader, CardBody };
