import React, { Children } from 'react';

import Card, { CardBody, CardHeader } from '../ui/Card';

const InstructionSteps = [
  'Bank transfer to the bank account(s) listed in the Payment Information.',
  'Send the payment receipt or payment proof of the bank transaction to your sales representative.',
];

const CashPaymentInstruction = () => {
  const bankAccountNumber = '00xxxxxxxxxx';

  return (
    <Card>
      <CardHeader>Payment Details</CardHeader>
      <CardBody>
        <div className="text-sm">
          <div className="flex flex-col gap-6">
            <Section title="Sales Representative">
              <DetailItem title="Name">Soraya Worasirigul</DetailItem>
              <DetailItem title="Line ID">ggbblablab</DetailItem>
              <DetailItem title="Phone">01234567890</DetailItem>
            </Section>
            <Section title="Payment Information">
              <DetailItem title="Bank Name">Bangkok Bank</DetailItem>
              <DetailItem title="Bank account name">TSA Shopping</DetailItem>
              <DetailItem title="Bank account number">
                {bankAccountNumber}{' '}
                <span
                  className="cursor-pointer text-primary-6 underline"
                  onClick={() => {
                    navigator.clipboard.writeText(bankAccountNumber);
                  }}
                >
                  Copy
                </span>
              </DetailItem>
            </Section>
            <Section title="Instructions">
              <ul className="col-span-2 flex w-full flex-col gap-1">
                {InstructionSteps.map((step, index) => (
                  <li key={index} className="flex">
                    <div className="mr-1 shrink-0 font-bold">
                      Step {index + 1}:
                    </div>
                    <div className="flex-1 overflow-hidden break-words">
                      {step}
                    </div>
                  </li>
                ))}
              </ul>
            </Section>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="grid grid-cols-[minmax(80px,180px),minmax(100px,1fr)] gap-1">
      <div className="col-span-2 mb-1 font-semibold text-light-title">
        {title}
      </div>
      {children}
    </div>
  );
};

const DetailItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="font-medium text-light-secondary">{title}</div>
      <div className="overflow-hidden break-words text-light-title">
        {children}
      </div>
    </>
  );
};

export default CashPaymentInstruction;
